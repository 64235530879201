<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <Calculator/>
      </div>
    </div>
  </div>
</template>

<script>
import Calculator from './components/Calculator.vue'

export default {
  name: 'App',
  components: {
    Calculator: Calculator
  }
}
</script>

<style>
#app {

}
</style>
