<template>
  <div id="pc">
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>姓名</td>
          <td><input type="text" v-model="name" style="width: 100px;"></td>
          <td>户口</td>
          <td>
            <select v-model="registeredSelected">
              <option v-for="option in registeredOptions" :key="option.value" :value="option.value">
                {{ option.value }}
              </option>
            </select>
          </td>
          <td>工资</td>
          <td><input type="text" v-model="salary" style="width: 100px;"></td>
          <td>工伤保险比例</td>
          <td><input type="text" v-model="proportionIllInput" style="width: 60px;"> %</td>
        </tr>
      </tbody>
    </table>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th colspan="7" class="center">社保缴费计算器</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>缴费人员姓名</td>
          <td>{{ name }}</td>
          <td>户口性质</td>
          <td>{{ registeredSelected }}</td>
          <td>工资</td>
          <td>{{ finalSalary }}</td>
          <td></td>
        </tr>
        <tr>
          <td colspan="2" class="backgroundBlue"></td>
          <td colspan="2" class="backgroundGreen">参保单位</td>
          <td colspan="2" class="backgroundPurple">参保个人</td>
          <td class="backgroundYellow"></td>
        </tr>
        <tr>
          <td class="backgroundBlue">缴费险种</td>
          <td class="backgroundBlue">缴费基数</td>
          <td class="backgroundGreen">缴费比例</td>
          <td class="backgroundGreen">月缴费金额</td>
          <td class="backgroundPurple">缴费比例</td>
          <td class="backgroundPurple">月缴费金额</td>
          <td class="backgroundYellow">参保合计</td>
        </tr>
        <tr>
          <td>养老保险</td>
          <td class="right">{{ baseOld }}</td>
          <td class="right">{{ proportionOldText }}</td>
          <td class="right">{{ moneyOld }}</td>
          <td class="right">{{ personProportionOldText }}</td>
          <td class="right">{{ personMoneyOld }}</td>
          <td class="right">{{ totalOld }}</td>
        </tr>
        <tr>
          <td class="backgroundBlue">失业保险</td>
          <td class="right backgroundBlue">{{ baseWork }}</td>
          <td class="right backgroundGreen">{{ proportionWorkText }}</td>
          <td class="right backgroundGreen">{{ moneyWork }}</td>
          <td class="right backgroundPurple">{{ personProportionWorkText }}</td>
          <td class="right backgroundPurple">{{ personMoneyWork }}</td>
          <td class="right backgroundYellow">{{ totalWork }}</td>
        </tr>
        <tr>
          <td>工伤保险</td>
          <td class="right">{{ baseIll }}</td>
          <td class="right">{{ proportionIllText }}</td>
          <td class="right">{{ moneyIll }}</td>
          <td colspan="2" class="center">个人不缴费</td>
          <td class="right">{{ totalIll }}</td>
        </tr>
        <tr>
          <td class="backgroundBlue">医疗保险（含生育）</td>
          <td class="right backgroundBlue">{{ baseMedical }}</td>
          <td class="right backgroundGreen">{{ proportionMedicalText }}</td>
          <td class="right backgroundGreen">{{ moneyMedical }}</td>
          <td class="right backgroundPurple">{{ personProportionMedicalText }}</td>
          <td class="right backgroundPurple">{{ personMoneyMedical }}</td>
          <td class="right backgroundYellow">{{ totalMedical }}</td>
        </tr>
        <tr>
          <td>总计</td>
          <td>-</td>
          <td>-</td>
          <td class="right">{{ moneySum }}</td>
          <td>-</td>
          <td class="right">{{ personMoneySum }}</td>
          <td class="right">{{ totalSum }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div id="mobile">

    <table id="mobileInput" class="table table-bordered table-secondary">
      <tbody>
        <tr>
          <td style="width: 15%">姓名</td>
          <td style="width: 25%"><input type="text" v-model="name" class="form-control" style="width: 100%;"></td>
          <td style="width: 30%">户口</td>
          <td style="width: 30%">
            <select v-model="registeredSelected" class="form-select" style="width: 100%;">
              <option v-for="option in registeredOptions" :key="option.value" :value="option.value">
                {{ option.value }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td>工资</td>
          <td><input type="text" v-model="salary" class="form-control" style="width: 100%;"></td>
          <td>工伤保险比例</td>
          <td>
            <div class="input-group">
              <input type="text" class="form-control" v-model="proportionIllInput">
              <span class="input-group-text">%</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table table-hover table-bordered">
      <thead>
        <tr class="table-primary">
          <th style="width: 34%;">缴费工资</th>
          <th class="center" style="width: 22%;" rowspan="2">单位</th>
          <th class="center" style="width: 22%;" rowspan="2">个人</th>
          <th class="center" style="width: 22%;" rowspan="2">合计</th>
        </tr>
        <tr>
          <th class="right table-primary">{{ finalSalary }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="table-info">养老</td>
          <td class="right table-success">{{ moneyOld }}</td>
          <td class="right table-danger">{{ personMoneyOld }}</td>
          <td class="right table-warning">{{ totalOld }}</td>
        </tr>
        <tr>
          <td class="table-info">工伤</td>
          <td class="right table-success">{{ moneyIll }}</td>
          <td class="right table-danger">-</td>
          <td class="right table-warning">{{ totalIll }}</td>
        </tr>
        <tr>
          <td class="table-info">失业</td>
          <td class="right table-success">{{ moneyWork }}</td>
          <td class="right table-danger">{{ personMoneyWork }}</td>
          <td class="right table-warning">{{ totalWork }}</td>
        </tr>
        <tr>
          <td class="table-info">医疗(含生育)</td>
          <td class="right table-success">{{ moneyMedical }}</td>
          <td class="right table-danger">{{ personMoneyMedical }}</td>
          <td class="right table-warning">{{ totalMedical }}</td>
        </tr>
        <tr>
          <td class="table-info">合计</td>
          <td class="right table-success">{{ moneySum }}</td>
          <td class="right table-danger">{{ personMoneySum }}</td>
          <td class="right table-warning">{{ totalSum }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Calculator',
  props: {},
  data() {
    return {
      // 常量
      proportionOld: 0.16, // 养老保险单位参保比例
      personProportionOld: 0.08, // 养老保险个人参保比例
      proportionWork: 0.005, // 失业保险单位缴费比例
      personProportionWork: 0.005, // 失业保险个人缴费比例
      proportionMedical: 0.098, // 医疗单位比例
      personProportionMedical: 0.02, // 医疗个人比例
      minSalary: 6326, // 最低工资
      maxSalary: 33891, // 最高工资
      // 户口选择
      registeredSelected: '城镇',
      registeredOptions: [
        { value: '城镇' },
        { value: '农村' }
      ],
      // 变量
      name: '', // 姓名
      salary: 6326, // 薪水
      proportionIllInput: 0.4, // 工伤保险比例（输入）
      // proportionIll: 0.004, // 工伤单位比例
    }
  },
  computed: {
    // 计算后的薪水
    finalSalary() {
      if (this.salary < this.minSalary) {
        return this.minSalary;
      }
      if (this.salary > this.maxSalary) {
        return this.maxSalary;
      }
      return this.salary;
    },
    // 养老保险单位参保比例显示
    proportionOldText() {
      return this.proportionOld * 100 + '%'
    },
    // 养老保险个人参保比例显示
    personProportionOldText() {
      return this.personProportionOld * 100 + '%'
    },
    // 失业保险单位缴费比例显示
    proportionWorkText() {
      return this.proportionWork * 100 + '%'
    },
    // 失业保险个人缴费比例显示
    personProportionWorkText() {
      return this.personProportionWork * 100 + '%'
    },
    // 工伤单位比例
    proportionIllText() {
      return this.proportionIll * 100 + '%'
    },
    // 医疗单位比例显示
    proportionMedicalText() {
      return this.proportionMedical * 100 + '%'
    },
    // 医疗个人比例显示
    personProportionMedicalText() {
      return this.personProportionMedical * 100 + '%+3'
    },
    // 养老保险基数
    baseOld() {
      return this.finalSalary > 3613 ? this.finalSalary : 3613
    },
    // 养老单位保险缴费金额
    moneyOld() {
      return Math.round(this.baseOld * this.proportionOld * 100) / 100
    },
    // 养老个人保险缴费金额
    personMoneyOld() {
      return Math.round(this.baseOld * this.personProportionOld * 100) / 100
    },
    // 养老保险总金额
    totalOld() {
      return Math.round((this.moneyOld + this.personMoneyOld) * 100) / 100
    },
    // 失业保险基数
    baseWork() {
      return this.finalSalary > 3613 ? this.finalSalary : 3613
    },
    // 失业保险单位缴费金额
    moneyWork() {
      return Math.round(this.baseWork * this.proportionWork * 100) / 100
    },
    // 失业保险个人缴费金额
    personMoneyWork() {
      return Math.round(this.baseWork * this.personProportionWork * 100) / 100
    },
    // 失业保险总金额
    totalWork() {
      return Math.round((this.moneyWork + this.personMoneyWork) * 100) / 100
    },
    // 工伤基数
    baseIll() {
      return this.finalSalary > 4713 ? this.finalSalary : 4713
    },
    // 单位工伤保险比例
    proportionIll() {
      return this.proportionIllInput / 100
    },
    // 工伤单位金额
    moneyIll() {
      return Math.round(this.baseIll * this.proportionIll * 100) / 100
    },
    // 工伤总金额
    totalIll() {
      return this.moneyIll
    },
    // 医疗基数
    baseMedical() {
      return this.finalSalary > 5360 ? this.finalSalary : 5360
    },
    // 医疗单位金额
    moneyMedical() {
      return Math.round(this.baseMedical * this.proportionMedical * 100) / 100
    },
    // 医疗个人金额
    personMoneyMedical() {
      return Math.round((this.baseMedical * this.personProportionMedical + 3) * 100) / 100
    },
    // 医疗总金额
    totalMedical() {
      return Math.round((this.moneyMedical + this.personMoneyMedical) * 100) / 100
    },
    // 单位总金额
    moneySum() {
      return Math.round((this.moneyOld + this.moneyWork + this.moneyIll + this.moneyMedical) * 100) / 100
    },
    // 个人总金额
    personMoneySum() {
      return Math.round((this.personMoneyOld + this.personMoneyWork + this.personMoneyMedical) * 100) / 100
    },
    // 总金额
    totalSum() {
      return Math.round((this.moneySum + this.personMoneySum) * 100) / 100
    },
  }
}
</script>

<style scoped>
#pc,
#mobile {
  padding: 1rem 0 0 0;
}

#mobile {
  display: none;
}

#mobileInput td {
  vertical-align: middle;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.backgroundBlue {
  background: dodgerblue;
}

.backgroundGreen {
  background: chartreuse;
}

.backgroundPurple {
  background: darkorchid;
}

.backgroundYellow {
  background: darkkhaki;
}

@media screen and (max-width: 780px) {
  #mobile {
    display: block;
  }

  #pc {
    display: none;
  }
}
</style>
